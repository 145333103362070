import React, { useEffect, useState } from "react";
import { beautifyAmount, isMobileView } from "../../utils";
import BrandingHeader from "./components/BrandingHeader";
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  PageHeader,
  Result,
  Row,
} from "antd";
import CartItem from "./components/CartItem";
import { RUPEE } from "../../models/constants";
import logo from "../../res/img/logoLandscapeV3.png";
import { PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { getCart, clearCart, saveCart } from "../../services/cookies";
import { getTotalCost } from "./cartTotal";
import { createEcommerceOrder, fetchOrderBasedOnOrderId } from "../../services/api/ecommerce";
import { getInternalCompany } from '../../services/api';

const CartPage = () => {
  const isMobile = isMobileView();
  const [visible, setVisible] = useState(false);
  const params = useParams();
  let {companyId} = params
  const [orderId, setOrderId] = useState();
  const navigate = useNavigate();
  const cartTotal = getTotalCost();
  const order = params.orderId;
  const [storeId, setStoreId] = useState(companyId)

  const cart = getCart();
  const [cartProducts, setCartProducts] = useState(cart);
  const [storeNumber, setStoreNumber] = useState();
  const length = cart.length;
  const [form] = Form.useForm();

  const getStoreInformation = async()=>{
    try{
        const response = await getInternalCompany(storeId);
        const storeInformation = _.get(response,'data.data.companies.0', {})
        companyId= storeInformation._id;
        setStoreId(storeInformation._id)
        // let storeImage = `/api/internal/file/${storeInformation?.imgLogo}` || 'https://vyaparwebsiteimages.vypcdn.in/catalogue/firm-logo-placeholder.png'
        setStoreNumber(storeInformation?.contactNumber);
    }catch(e){
        message.error('Error in Fetching Store Information')
        navigate(`/store/${companyId}`);
    }
}


  const fetchOrderOnOrderId = async(orderId)=>{
    try{
      const response = await fetchOrderBasedOnOrderId(orderId);
      const order = _.get(response, 'data.data.orders.0', false);
      if(!order){
        throw new Error('No orders');
      }
    }catch(e){
      console.error('Error in Fetching orders', e);
    }
  }

  useEffect(()=>{
      setStoreId(companyId)
      setCartProducts(cart);
      getStoreInformation();
      if(order){
        fetchOrderOnOrderId(order);
      }
  }, [])


  const remove = async(item, val)=>{
    const formValues = form.getFieldValue();
      const result = cartProducts.filter((cart) => cart.name !== val.name);
      await saveCart(result);
      setCartProducts(result);
      form.setFieldsValue(formValues)
  }

  const modify = async(item )=>{
    const formValues = form.getFieldValue();
    const results = cartProducts.map((cart)=>{
      if(cart.name === item.name){
        cart.qty = item.qty
      }
      return cart;
    })
    await saveCart(results);
    setCartProducts(results)
    form.setFieldsValue(formValues)
  }

  useEffect(()=>{
    OrderSummary()
  }, [cartProducts])

  const OrderSummary = () => (
    <>
      <Row align={"middle"}>
        <Col span={12} style={{ fontWeight: "bold", fontSize: 16 }}>
          Order Summary
        </Col>
        <Col
          span={12}
          style={{ textAlign: "right", color: "#444", fontSize: 14 }}
        >
          {length} items
        </Col>
      </Row>
      {!isMobile && <Divider />}
      {cartProducts.map((item) => (
        <CartItem item={item} remove={event=>remove(event, item)} modify={event=> modify(item)} />
      ))}
    </>
  );

  const TotalAmount = () => (
    <Row
      style={{
        marginTop: 12,
        background: isMobile ? "#fff" : "#eeeefa",
        borderRadius: 8,
        border: (isMobile ? "0" : "1") + "px solid lightgrey",
        padding: 16,
        fontSize: 16,
        fontWeight: "bold",
      }}
    >
      <Col span={12}>Total Amount</Col>
      <Col span={12} style={{ textAlign: "right" }}>
        {RUPEE} {beautifyAmount(cartTotal)}
      </Col>
    </Row>
  );

  const createOrder = async ({userDetails})=>{
    const status = 'pending';
    let obj = {
      company :storeId,
      customerDetails: userDetails,
      amount :cartTotal,
      status,
      items:cartProducts 
    }
    try{
      const response = await createEcommerceOrder(obj);
      const orderId = _.get(response,'data.data.orderId', false)
      setOrderId(orderId);
      setVisible(true)
    }catch(e){
      console.error('Error in Response', e);
    }
  }

  const AddressForm = () => {
    return (
    <div
      style={{
        background: isMobile ? "#fff" : "#eeeefa",
        borderRadius: 8,
        border: (isMobile ? "0" : "1") + "px solid lightgrey",
        padding: 16,
      }}
    >
      <h3 style={{ fontWeight: "bold" }}>Delivery Address</h3>
      <Form
      form={form}
      >
        <Form.Item
          name={"name"}
          style={{ marginBottom: 12 }}
          required={true}
          rules={[
            {
              required: true,
              message: "Please enter your name!",
            },
          ]}
        >
          <Input
            placeholder={"Full Name"}
            style={{ borderRadius: 4, padding: 8 }}
          />
        </Form.Item>
        <Form.Item
          name={"phone"}
          style={{ marginBottom: 12 }}
          required={true}
          rules={[
            {
              required: true,
              message: 'Please enter valid phone number',
              validator:(record, val)=>{
                  // console.log(!val?.match?.(/^[0-9]*$/gi))
                  if(val?.match?.(/^[0-9]*$/gi)){
                    return Promise.resolve()
                  }
                 return Promise.reject()
                  
              }
            },
          ]}
        >
          <Input
            prefix={"+91"}
            placeholder={"Contact Number"}
            style={{ borderRadius: 4, padding: 8 }}
          />
        </Form.Item>
        <Form.Item
          name={"address"}
          style={{ marginBottom: 12 }}
          required={true}
          rules={[
            {
              required: true,
              message: "Please enter your address!",
            },
          ]}
        >
          <Input.TextArea
            placeholder={"Address"}
            rows={2}
            style={{ borderRadius: 4, padding: 8 }}
          />
        </Form.Item>
      </Form>
    </div>)
  };

  const btnProps = isMobile
    ? { position: "fixed", bottom: 0, borderRadius: 0 }
    : {};
  const PlaceButton = () => (
    <Button
      type={"primary"}
      style={{
        marginTop: 12,
        width: "100%",
        borderRadius: 8,
        height: 56,
        ...btnProps,
      }}
      onClick={() => {
        form.validateFields().then((userDetails)=>{
          createOrder({userDetails})
        }).catch((e)=>{
          console.error('Error in creating order', e);
        })
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: 18 }}>Place Order</div>
      <div style={{ fontSize: 12 }}>COD (Cash On Delivery)</div>
    </Button>
  );

  return (
    <div>
      {!isMobile && <BrandingHeader />}
      {isMobile ? (
        <div style={{ paddingBottom: 100 }}>
          <PageHeader
            ghost={false}
            onBack={() => {
              navigate(`/store/${storeId}`);
            }}
            title={`My Cart`}
            style={{
              padding: 6,
              paddingLeft: 12,
              borderBottom: "1px solid lightgrey",
            }}
          />
          <AddressForm />
          <Divider style={{ margin: 0 }} />
          <div style={{ padding: 16 }}>
            <OrderSummary />
          </div>

          <Divider style={{ margin: 0 }} />
          <TotalAmount />

          <PlaceButton />
        </div>
      ) : (
        <>
          <Row
            style={{
              width: "100%",
              marginTop: 16,
              paddingLeft: isMobile ? 12 : "11%",
              paddingRight: isMobile ? 12 : "11%",
              paddingBottom: 100,
            }}
          >
            <Col
              span={16}
              style={{
                background: "#eeeefa",
                padding: 16,
                verticalAlign: "middle",
                borderRadius: 8,
                border: "1px solid lightgrey",
              }}
            >
              <OrderSummary />
            </Col>
            <Col span={8} style={{ paddingLeft: 12 }}>
              <AddressForm />

              <TotalAmount />

              <PlaceButton />
            </Col>
          </Row>
        </>
      )}

      <Modal
        visible={visible}
        title={"Order Placed"}
        footer={null}
        onCancel={() => {
          setVisible(false);
          clearCart();
          navigate(`/store/${storeId}`);
        }}
      >
        <Result
          status="success"
          title="Order Placed!"
          subTitle={
            <>
              Your Order was placed successfully from Company Name
              <br />
              Order ID: {orderId}
            </>
          }
          extra={[
            <Button
              className={"btnCustom4 hoverOrangeBorder borderLightGrey"}
              style={{
                marginTop: 4,
                background: "#ffecb3",
                color: "#ff6f00",
                padding: 0,
                height: 36,
                fontWeight: "bold",
                fontSize: 14,
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              <a href={'tel:'+storeNumber} style={{"color":"ff6f00"}}><PhoneOutlined /> Call Store</a>
            </Button>,
            <Button
              className={"btnCustom4 borderLightGrey"}
              style={{
                marginTop: 4,
                background: "#e8f5e9",
                color: "#1b5e20",
                padding: 0,
                height: 36,
                fontWeight: "bold",
                fontSize: 14,
                paddingLeft: 8,
                paddingRight: 8,
              }}
              onClick={(e)=>{
                window.open(`https://api.whatsapp.com/send?phone=${storeNumber}&text=%22hi%22`, '_blank');
            }}
            >
              <WhatsAppOutlined /> Whatsapp
            </Button>,
          ]}
        />
        <div style={{ textAlign: "center" }}>
          Powered By&nbsp;&nbsp;
          <Image style={{ height: 24 }} preview={false} src={logo} />
        </div>
      </Modal>
    </div>
  );
};

export default CartPage;
