import Cookies from "js-cookie";
import { getCurrentFinancialYear } from "./functions";
import { kbConstants } from "../models/constants/kbConstants";
const moment = require('moment');

export const getCart = () => {
    try {
        const cart = JSON.parse(Cookies.get('invCart'));
        return cart;
    } catch(err) {
        return [];
    }
}

export const saveCart = (cart = []) => {
    Cookies.set("invCart", JSON.stringify(cart), {expires: 5});
    console.log("## Saving Cart ##", JSON.stringify(cart));
}

export const clearCart = ()=>{
    Cookies.remove("invCart");
}

export const getDateRange = () => {
    try {
        var dateRange = JSON.parse(Cookies.get('dateRange'));
        dateRange = dateRange.length === 1 ? [] : dateRange.map(dateString => moment(dateString));
        return dateRange;
    } catch(err) {
        return getCurrentFinancialYear();
    }
}

export const saveDateRange = (dateRange) => {
    dateRange = dateRange.map(dateString => dateString.startOf().valueOf());
    Cookies.set("dateRange", JSON.stringify(dateRange), {expires: kbConstants.LOGIN_EXPIRY});
}

export const clearDateRange = ()=>{
    Cookies.remove("dateRange");
}