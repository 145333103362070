import React, { useState } from "react";
import { Button, Card, Col, Form, message, Row, Upload } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { createBackup } from "../../services/apiV2/reports";

const allowedFileTypes = [
    "application/zip",
    "application/x-zip-compressed"
];

export const ReportsBackupComponent = () => {
    const [form] = Form.useForm();
    const [isExporting, setIsExporting] = useState(false);
    const [isRestoring, setIsRestoring] = useState(false);

    const handleCreateBackup = async () => {
        try {
            setIsExporting(true);
            const response = await createBackup();
            
            const blob = response;
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = `backup_${Date.now()}.kb_backup`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            message.success('Backup created successfully');
        } catch (err) {
            message.error(err.response?.headers?.custommsg || "Failed to create backup. Please contact Customer Support");
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div style={{ padding: 30 }}>
            <h2>Backup & Restore</h2>
            
            <Row gutter={[24, 24]}>
                {/* Create Backup Section */}
                <Col span={24}>
                    <Card title="Create Backup">
                        <p>Click the button below to create and download a backup of your data.</p>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            loading={isExporting}
                            onClick={handleCreateBackup}
                        >
                            Create Backup
                        </Button>
                    </Card>
                </Col>

                {/* Restore Backup Section */}
                <Col span={24}>
                    <Card title="Restore from Backup">
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={async (values) => {
                                try {
                                    setIsRestoring(true);
                                    // The actual file upload and restore logic will be handled by the Upload component
                                    message.success('Backup restored successfully');
                                } catch (err) {
                                    message.error('Failed to restore backup');
                                } finally {
                                    setIsRestoring(false);
                                }
                            }}
                        >
                            <Form.Item 
                                name="backupFile"
                                rules={[{ required: true, message: 'Please upload a backup file' }]}
                            >
                                <Upload
                                    accept=".kb_backup"
                                    customRequest={({ onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 2000);
                                    }}
                                    maxCount={1}
                                    showUploadList={{
                                        showRemoveIcon: true,
                                    }}
                                    beforeUpload={(file) => {
                                        const isLt10M = file.size / 1024 / 1024 < 10;
                                        if (!isLt10M) {
                                            message.error('File must be smaller than 10MB!');
                                            return false;
                                        }
                                        return true;
                                    }}
                                    onChange={(info) => {
                                        if (info.file.status === 'done') {
                                            message.success('Imported successfully');
                                        } else if (info.file.status === 'error') {
                                            message.error(`${info.file.name} backup restore failed`);
                                        }
                                    }}
                                >
                                    <Button icon={<UploadOutlined />} loading={isRestoring}>
                                        Click to Upload Backup
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
