import React, {useEffect, useState} from "react";
import {Avatar, Button, Col, Form, Input, List, message, Row, Skeleton, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {loginRequest, saveUser} from "../services/api";
import {customDispatcher, isErpWeb, isPuc} from "../utils";
import ModalChangePassword from "./ChangePassword";
import {CheckCircleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const fallbackImg = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi33sxKaUgj-fMZEkgeXQ3V6kYcZPZCgU2q_xhY3GwgoiV1wNq01NkzUI_6TX6spmxzaw&usqp=CAU";

const MyAvatar = ({source}) => {
    const [src, setSrc] = useState();

    useEffect(() => {
        setSrc(source);
    }, [source])

    return (
        <Avatar
            size={64}
            src={src}
            onError={(e) => {
                setSrc(fallbackImg);
                return false;
            }}
        />
    )
}

const ProfileSettings = () => {
    const puc = isPuc();
    const state = useSelector(state => state.oldState);
    const dispatch = customDispatcher(useDispatch());
    const [form] = Form.useForm();
    const [showChangePass, setShowChangePass] = useState(false);

    console.log("## state ##", state);
    useEffect(() => {
        form.setFieldsValue(state?.user);
    }, [state, form]);

    return (
        <div>
            <h2>Profile</h2>
            <Row>
                <Col span={10} style={{paddingRight: 64}}>
                    <Form
                        form={form}
                        name="form_in_modal"
                        layout={"vertical"}
                        initialValues={{}}
                    >
                        <Form.Item name={"email"} label={"Email (Username)"}>
                            <Input disabled={true}/>
                        </Form.Item>
                        <Form.Item name={"name"} label={"Full Name"}>
                            <Input/>
                        </Form.Item>
                        {
                            isPuc() &&
                            <Form.Item name={"businessName"} label={"Business Name"}>
                                <Input/>
                            </Form.Item>
                        }
                        <Form.Item name={"phone"} label={"Phone"}>
                            <Input maxLength={10}/>
                        </Form.Item>
                        <Button type={"primary"} onClick={() => {
                            form.validateFields().then(async (values) => {
                                if (!values.name) {
                                    message.error("Invalid Name");
                                    return;
                                }
                                await saveUser({
                                    user: {
                                        _id: state.user._id,
                                        name: values.name,
                                        businessName: values.businessName,
                                        phone: values.phone,
                                    }
                                });
                                await loginRequest("", "", dispatch);
                                message.success("Profile details updated successfully");
                            }).catch(() => {
                            });
                        }}>Save</Button>
                        <br/>
                        <br/>
                        <Button type={"link"} onClick={() => setShowChangePass(true)}><u><strong>Change
                            Password</strong></u></Button>
                    </Form>
                </Col>
                {
                    !puc && !isErpWeb() &&
                    <Col span={12}>
                        <h3>Linked Companies</h3>
                        <List
                            itemLayout={"horizontal"}
                            dataSource={state?.user?.companies || []}
                            renderItem={item => {
                                return (
                                    <List.Item
                                        actions={
                                            state?.company?._id === item._id ?
                                                [<Tag icon={<CheckCircleOutlined/>} color="success">
                                                    Default
                                                </Tag>] :
                                                [
                                                    <Link to={"#"} key="list-loadmore-edit" onClick={async () => {
                                                        await saveUser({
                                                            user: {
                                                                _id: state?.user?._id,
                                                                company: item._id
                                                            }
                                                        });
                                                        await loginRequest("", "", dispatch);
                                                        message.success("Company Switched Successfully !");
                                                    }}><u>Set to Default</u></Link>
                                                ]}
                                    >
                                        <Skeleton avatar title={false} loading={item.loading} active>
                                            <List.Item.Meta
                                                avatar={
                                                    <MyAvatar source={`/api/internal/file/${item.imgLogo}`}/>
                                                }
                                                title={
                                                    <div>
                                                        <div>{item.name} <small style={{color: '#999'}}>({item._id.toString().slice(-6)})</small></div>
                                                        <div style={{fontSize: '12px', color: '#666'}}>
                                                            Users: {item.usersList?.join?.(', ')}
                                                        </div>
                                                    </div>
                                                }
                                                description={`${item.address}`}
                                            />
                                        </Skeleton>
                                    </List.Item>
                                )
                            }}
                        />
                    </Col>
                }
            </Row>
            <ModalChangePassword
                visible={showChangePass}
                onCancel={() => setShowChangePass(false)}
            />
        </div>
    )
};

export default ProfileSettings;
