import React, { useState, useCallback, useEffect } from "react";
import { Form, DatePicker, Select, message, Input } from "antd";
import { baseUrl, externalApiInstance } from "../../services/api";
import moment from "moment";
import { useSelector } from "react-redux";
import { ButtonV2 } from "../../components/ButtonV2";
import { getPresetDateRange, getCurrentFinancialYear } from "../../services/functions";
import { prepareForTallyReport } from "../../services/apiV2/reports";
const { RangePicker } = DatePicker;

const ReportTallyExport = () => {
    const state = useSelector(state => state.oldState);
    const [form] = Form.useForm();
    const [currentState, setCurrentState] = useState("inputDateRange"); // "inputDateRange" or "inputMappings"
    const [extraInputs, setExtraInputs] = useState([]);
    const presetDateRange = { ...getPresetDateRange() }
    delete presetDateRange['Today']

    useEffect(() => {
        form.setFieldsValue({
            dateRange: getCurrentFinancialYear()
        })
    }, []);


    const handleNext = useCallback(async () => {
        try {
            const formData = await form.validateFields();
            console.log("## Form Data ##", formData);
            const response = await prepareForTallyReport({
                from: formData.dateRange[0].startOf('month').valueOf(),
                to: formData.dateRange[1].endOf('month').valueOf()
            });
            setExtraInputs(response.extraInputs);
            setCurrentState("inputMappings");
            form.setFieldsValue({
                ...response.extraInputs.reduce((acc, input) => {
                    acc[input.name] = input.currentValue;
                    return acc;
                }, {})
            });
        } catch(err) {
            message.error("Something went wrong, Please contact Customer Support");
        }
    }, [form]);

    const handleGenerate = useCallback(async () => {
        console.log("## Handling Generate ##");
        const formData = await form.validateFields();
        console.log("## FormData ##", formData);

        try {
            let blob;
                    try {
                        const extraInputsArgs = extraInputs.reduce((acc, input) => {
                            acc[input.name] = formData[input.name];
                            return acc;
                        }, {});
                        console.log("## Extra Inputs ##", extraInputsArgs);
                        const params = {
                            company: state?.company?._id,
                            type: "tallyErp",
                            from: formData.dateRange[0].startOf('month').valueOf(),
                            to: formData.dateRange[1].endOf('month').valueOf(),
                            extraInputsArgs: JSON.stringify(extraInputsArgs),
                        };
                        // const response = await generateReportsFile(params);
                        const response = await externalApiInstance.get(`${baseUrl}/internal/export?` + new URLSearchParams(params), {
                            responseType: 'blob'
                        });
                        // console.log("## Response ##", response.status, response);
                        blob = response.data;
                    } catch (err) {
                        console.error("## Error in exporting data ##", err);
                        message.error(err.response?.headers?.custommsg || "Something went wrong, Please contact Customer Support");
                        return;
                    }


                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    console.log("url is", url)
                    const link = document.createElement('a');
                    link.href = url;
                    const timeStr = moment().format("DDMMYYYYHHmmss");
                    let fileName;
                    fileName = `kbExportForTally_${Date.now() % 1000}.zip`;
                    link.setAttribute(
                        'download',
                        fileName
                    );
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
        } catch(err) {
            console.error("## Error in exporting data (outer catch) ##", err);
            message.error("Something went wrong while exporting data, Please contact Customer Support");
        }
        
                    

    }, [extraInputs, form, state?.company?._id]);


    return (
        <div>
            <h2>Export Data to Tally ERP / Prime</h2>
            <Form
                form={form}
                layout={"vertical"}
                initialValues={{}}
            >
                <Form.Item name={"dateRange"} label={"Date Range"}
                    rules={[{ required: true, message: "can't be blank" }]}>
                    <RangePicker
                        picker="month"
                        ranges={presetDateRange}
                    />
                </Form.Item>

                {
                    currentState !== "inputDateRange" && (
                        <>
                            <div style={{marginBottom: '20px'}}>
                                <h3>Configure Tally Ledger Names</h3>
                                <p style={{color: '#666666'}}>
                                    Please provide the exact ledger names as configured in your Tally software. 
                                    These ledgers will be used to map transactions during export.
                                </p>
                            </div>
                            {extraInputs.map((input, index) => (
                                <Form.Item style={{maxWidth: '400px'}} key={index} name={input.name} label={input.label}
                                    rules={[{ required: true, message: "can't be blank" }]}>
                                    <Input placeholder={input.placeholder} />
                                </Form.Item>
                            ))}
                        </>
                    )
                }


                <ButtonV2 type={"primary"} onClick={async () => {
                    try {
                        if (currentState === "inputDateRange") {
                            await handleNext();
                        } else {
                            await handleGenerate();
                        }
                    } catch (err) {
                        console.error("## Error in exporting Tally Reports ##", err);
                        message.error("Something went wrong, Please contact Customer Support");
                    }
                }}>
                    {currentState === "inputDateRange" ? "Next" : "Export Data"}
                </ButtonV2>
            </Form>
        </div>
    )
};

export default ReportTallyExport;
