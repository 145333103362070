import axios from "axios";
import {message} from "antd";
import {baseUrl} from "../api";

export const apiKbExternalInstance = axios.create({
    baseURL: `${baseUrl}/v2.0/kb/external`,
});

apiKbExternalInstance.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    if (error?.response?.status === 401) {
        message.error("Unauthorized");
    }
    return Promise.reject(error);
});

