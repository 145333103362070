import React, { useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { InputNumber, Button, Table, message } from "antd";

import rupeeCoin from '../../res/img/walletCoin.png';

import { RUPEE } from "../../models/constants";

import {
  fetchWalletTransactions,
  initiateWalletRecharge,
} from "../../services/api";

const WalletSettings = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state.oldState);

  const [addCoins, setAddCoins] = useState(100);
  const [loading, setLoading] = useState(false);
  const [walletTransaction, setWalletTransaction] = useState([]);
  const [existingCoin, setExistingCoin] = useState("--")

  const FetchWalletTransactions = async () => {
    setLoading(true);
    const Data = await fetchWalletTransactions();
    const resTransaction = _.get(Data, "data.data.transactions", []);
    setWalletTransaction(resTransaction);
    setLoading(false);
    setExistingCoin(Data?.data?.data?.balance);
  };

  const WalletRecharge = async () => {
    if (addCoins > 10 && addCoins <= 10000) {
      const Data = await initiateWalletRecharge({ amount: addCoins });
      navigate(`/pay/${Data}/gateway`);
    } else {
      message.error(addCoins < 11 ? "Please add Coins more than 10" : "KYC required for more than 10,000 coins");
    }
  };

  const CoinIconImg = () => {
    return (
      <CoinIcon>
      <img
        alt={"CashFree"}
        src={rupeeCoin}
        width={15}
      />
      </CoinIcon>
    )
  };

  useEffect(() => {
    FetchWalletTransactions();
  }, []);

  const columns = [
    {
      title: "Date",
      render: (obj) => moment(obj.date).format("DD MMM YYYY"),
      sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
      defaultSortOrder: "descend",
    },
    {
      title: "Change",
      weight: 1,
      render: (obj) => (
        <b><ExistingCoins>{obj.amount} {CoinIconImg()}</ExistingCoins></b>
      ),
    },
    {
      title: "New Balance",
      weight: 1,
      render: (obj) => (
        <b><ExistingCoins>{obj.newBalance} {CoinIconImg()}</ExistingCoins></b>
      ),
    },
    {
      title: "Remarks",
      weight: 1,
      render: (obj) => obj.remarks,
    },
  ];

  return (
    <>
      <Heading>KhataBuddy Wallet</Heading>
      <InfoMessage>
        Wallet coins let you send documents (invoices, ledger statements, etc.) directly to your clients via SMS, WhatsApp, or Email from KhataBuddy - no need to manually download and share documents separately.
      </InfoMessage>
      <TopContainer>
        <LeftContainer>
          <CoinsContainer>
            Coins :
            <ExistingCoins>
              { existingCoin }
              {CoinIconImg()}
            </ExistingCoins>
          </CoinsContainer>
          <AddCoinsContainer>
            Add Wallet Coins :
            <InputNumber
              min={1}
              defaultValue={addCoins}
              onChange={(e) => {
                setAddCoins(e);
              }}
            />
          </AddCoinsContainer>
          <CoinRupeeCalcContainer>
            ( {RUPEE} {1} = {1 * 2} { CoinIconImg()})
          </CoinRupeeCalcContainer>
          <Button
              type="primary"
              style={{borderRadius: "8px", width: "120px"}}
              disabled={(state.company ? !state.company?.pgConfig?.enabled : false) || false}
              onClick={() => {
                // message.error(`KYC required for Wallet Recharge`);
                WalletRecharge()
              }}
          >
            Recharge
          </Button>
          {state.company ?  (!state.company?.pgConfig?.enabled && false &&
      <CoinRupeeCalcContainer>Please enable Payment Gateway to add coins</CoinRupeeCalcContainer>) : ''}
        </LeftContainer>
        <RightContainer>
          <WalletHeading>Wallet Credit Usage</WalletHeading>
          <WalletTableRow>
            <LeftContent>Type</LeftContent>
            <RightContent>No. Of Coins</RightContent>
          </WalletTableRow>
          <WalletTableRow>
            <LeftContent>1 SMS</LeftContent>
            <RightContent>
              1{" "}
              { CoinIconImg()}
            </RightContent>
          </WalletTableRow>
          <WalletTableRow>
            <LeftContent>1 E-Mail</LeftContent>
            <RightContent>
              1{" "}
              { CoinIconImg()}
            </RightContent>
          </WalletTableRow>
          <WalletTableRow>
            <LeftContent>1 Whatsapp</LeftContent>
            <RightContent>
              2{" "}
              { CoinIconImg()}
            </RightContent>
          </WalletTableRow>
        </RightContainer>
      </TopContainer>

      <BottomContainer>
        <WalletHeading>Usage Timeline</WalletHeading>
        &nbsp;
        <Table
          loading={loading}
          columns={columns}
          dataSource={walletTransaction}
        />
      </BottomContainer>
    </>
  );
};

export default WalletSettings;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  align-items: baseline;
`;

const BottomContainer = styled.div`
  margin-top: 25px;
`;

const Heading = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const CoinsContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: green;
  display: flex;
  gap: 10px;
`;

const AddCoinsContainer = styled.div`
  font-size: 16px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  width: 50%;
`;

const LeftContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WalletHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid lightgrey;
`;

const WalletTableRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const LeftContent = styled.div`
  width: 50%;
  font-size: 16px;
  font-weight: bold;
`;

const RightContent = styled.div`
  width: 43%;
  font-size: 16px;
  font-weight: bold;
  color: green;
  display: flex;
  gap: 2px;
`;

const CoinRupeeCalcContainer = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: gray;
  display: flex;
  gap: 4px;
`;

const CoinIcon = styled.div`
  color: #ec9e00;
`;

const ExistingCoins = styled.div`
  display: flex;
  gap: 5px;
`;

const InfoMessage = styled.div`
  color: #666;
  font-size: 14px;
  margin: 8px 0;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border-left: 4px solid #1890ff;
`;
