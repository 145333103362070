import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getLinkInfo, internalSaveUser, signup} from "../services/api";
import {Button, Form, Input, message, Row} from "antd";
import Cookies from "js-cookie";
import {kbConstants} from "../models/constants/kbConstants";
import { ButtonV2 } from "../components/ButtonV2";

const SignupWithoutCompany = ({type, companyName, email, fetchLinkInfo, userId, setView, setMessage}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({email});
    }, [email, form])

    return (
        <div
            style={{marginTop: 150, textSize: 23, background: '#cccccc', width: '30%', marginLeft: '35%', padding: 30}}>
            <Row>
                {
                    type === "signup" ?
                        <h3>Sign up to join [{companyName}]</h3> :
                        <h3>Reset Password</h3>
                }
            </Row>
            <Form
                form={form}
                layout={"vertical"}
                initialValues={{}}
            >
                <Form.Item name={"email"} label={"Email"} rules={[{required: true, message: "can't be blank"}]}>
                    <Input disabled={true}/>
                </Form.Item>
                {
                    type === "signup" &&
                    <Form.Item name={"name"} label={"Full Name"} rules={[{required: true, message: "can't be blank"}]}>
                        <Input autoFocus/>
                    </Form.Item>
                }
                <Form.Item name={"password"} label={"Password"} rules={[{required: true, message: "can't be blank"}]}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item name={"confirmPass"} label={"Confirm Password"} rules={[{required: true, message: "can't be blank"}]}>
                    <Input.Password/>
                </Form.Item>
                <ButtonV2 type={"primary"} onClick={async () => {
                    const values = await form.validateFields();
                    if (values.password !== values.confirmPass) {
                        message.error("Passwords don't match. Please ensure same password is typed in 'Password' and 'Confirm Password' fields");
                        return;
                    }
                    if (type === "signup") {
                        await signup({data: values});
                        fetchLinkInfo();
                    } else {
                        await internalSaveUser({
                            user: {
                                _id: userId,
                                email: values.email,
                                password: values.password
                            }
                        });
                        message.success("Password changed successfully");
                        setMessage("Password changed successfully. Redirecting to dashboard in 5 seconds...")
                        setView("redirect");
                    }
                    Cookies.set("invUser", values.email, {expires: kbConstants.LOGIN_EXPIRY});
                    Cookies.set("invPass", values.password, {expires: kbConstants.LOGIN_EXPIRY});
                }}>Create Account</ButtonV2>
            </Form>

        </div>
    )
}

const fetchLinkInfoStatic = ({key, setView, setInfo}) => {
    getLinkInfo(key).then(({data: {success, data: {info}}}) => {
        if (!success) {
            setView('expired');
        } else {
            setView(info.component);
            setInfo(info);
        }
    });
}

const VerifyLink = () => {
    const params = useParams();
    const navigate = useNavigate();
    const {key} = params;

    const [message, setMessage] = useState("Company added to your account successfully, redirecting to dashboard in 5 seconds...");
    const [view, setView] = useState('default');
    const [info, setInfo] = useState({});

    const fetchLinkInfo = () => fetchLinkInfoStatic({key, setInfo, setView});

    useEffect(() => {
        fetchLinkInfoStatic({key, setInfo, setView});
    }, [key]);

    console.log("## View Now ##", view);
    switch (view) {
        case 'default':
            return <div>Verifying ... </div>;
        case 'expired':
            return <div>Sorry, this link is either invalid or has been expired.</div>;
        case "redirect":
            // message.success("Company added to your account");
            setTimeout(() => {
                navigate("/dashboard");
            }, 5000);
            return <div>{message}</div>
        case 'signupWithoutCompany':
            return <SignupWithoutCompany
                type={"signup"}
                companyName={info.companyName}
                email={info.email}
                userId={info.userId}
                fetchLinkInfo = {fetchLinkInfo}
                setView ={setView}
                setMessage={setMessage}
            />;
        case 'resetPassword':
            return <SignupWithoutCompany
                type={"resetPassword"}
                companyName={""}
                email={info.email}
                userId={info.userId}
                fetchLinkInfo={fetchLinkInfo}
                setView ={setView}
                setMessage={setMessage}
                />
        default:
            return <div>404</div>
    }
};

export default VerifyLink;
