import _ from "lodash";
import {units} from "../models/constants";
import {exportedUrls, getVoucherPdf} from "../services/api";
import {useMediaQuery} from "react-responsive";
import {ExternalError} from "../erp/models/error";

export const customDispatcher = (reduxDispatch) => (props) => reduxDispatch({type: "original", props});

const getNum = (num) => Math.round(100 * num) / 100;

export const formulateItems = ({items, dbItemsMap, isIgst, extraDiscount = {}, isAbroad = false, isRoundOffEnabled}) => {
    const newItems = _.cloneDeep(items) || [];
    let subtotal = 0, totalCgst = 0, totalIgst = 0, totalSum = 0, totalTaxSum = 0;
    newItems.forEach((item, index) => {
        let dbItem = dbItemsMap[item._id];
        if (!dbItem) {
            if (item.fallbackItemDetails) {
                dbItem = {
                    ...item.fallbackItemDetails
                };
            } else {
                dbItem = {};
            }
        }
        item.name = dbItem.name;
        item.invDescription =
            <div>{dbItem.name}<br/><span style={{fontWeight: 'normal'}}>{item.description}</span></div>
        item.hsn = dbItem.hsn;
        item.qty = `${item.qty} (${units[dbItem.unit || "NOS"]?.code})`;
        let itemTaxRate = item.taxRate;
        if (isAbroad) {
            itemTaxRate = 0;
        }
        const taxRate = isAbroad ? 0 : itemTaxRate / 100;
        const rate = item.priceType === 'exc' ? item.price : (item.price / (1 + taxRate));
        const {discountValue = 0, discountType = "percent"} = item;
        let discount, discountPercentage;
        if (discountValue) {
            if (discountType === "percent") {
                discount = (rate * discountValue) / 100;
                discountPercentage = discountValue;
            } else {
                discount = item.priceType === 'exc' ? discountValue : (discountValue / (1 + taxRate));
                discountPercentage = (discount / rate) * 100;
            }
        }

        const taxable = (rate - (discount || 0)) * parseInt(item.qty);
        const cgst = isIgst ? 0 : (taxable * taxRate / 2);
        const igst = isIgst ? (taxable * taxRate) : 0;
        item.sno = (index + 1).toString();
        item.cgstRate = getNum(itemTaxRate / 2);
        item.price = getNum(rate);
        item.discount = discountValue ? <>
            {getNum(discount).toFixed(2)}
            <br/>
            <div style={{color: 'grey', fontSize: 11, fontWeight: 'normal'}}>({getNum(discountPercentage).toFixed(2)} %)</div>
        </> : "---";
        item.taxable = getNum(taxable);
        item.cgst = isIgst ? 0 : getNum(cgst);
        item.sgst = item.cgst;
        item.igst = isIgst ? getNum(igst) : 0;
        item.txtCgst = `${item.cgst.toFixed(2)} (${item.cgstRate}%)`;
        item.txtSgst = item.txtCgst;
        item.txtIgst = `${item.igst.toFixed(2)} (${itemTaxRate}%)`;
        const totalTax = (isIgst ? item.igst : (2 * item.cgst));
        const total = item.taxable + totalTax;
        item.total = getNum(total);
        subtotal += item.taxable;
        totalCgst += item.cgst;
        totalIgst += item.igst;
        totalTaxSum += totalTax;
        totalSum += item.total;
        item.price = item.price.toFixed(2);
        item.taxable = item.taxable.toFixed(2);
        // item.cgst = item.cgst.toFixed(2);
        item.total = item.total.toFixed(2);
        item.totalTax = totalTax.toFixed(2);
    });

    const totalSumBeforeDiscount = totalSum;

    const {extraDiscountValue, extraDiscountType} = extraDiscount || {};
    let discount, discountPercentage;
    if (extraDiscountValue) {
        if (extraDiscountType === "percent") {
            discount = (totalSum * extraDiscountValue) / 100;
            discountPercentage = extraDiscountValue;
        } else {
            discount = Number(extraDiscountValue);
            discountPercentage = (discount / totalSum) * 100;
        }

        if (discount) {
            totalSum -= discount;
        }
    }


    const roundedTotal = isRoundOffEnabled ? Math.round(totalSum) : totalSum;
    const roundedOff = isRoundOffEnabled ? (roundedTotal - totalSum) : 0;


    return {
        tableItems: newItems,
        summary: {
            subtotal: getNum(subtotal).toFixed(2),
            totalCgst: getNum(totalCgst).toFixed(2),
            totalIgst: getNum(totalIgst).toFixed(2),
            totalTax: getNum(totalTaxSum).toFixed(2),
            totalSum: getNum(totalSum).toFixed(2),
            totalSumBeforeDiscount: getNum(totalSumBeforeDiscount).toFixed(2),
            roundedOff: getNum(roundedOff).toFixed(2),
            extraDiscount: discount && {
                title:<>
                    Extra Discount <span style={{color: 'grey', fontSize: 11, fontWeight: 'normal'}}>({getNum(discountPercentage).toFixed(2)} %)</span>
                </>,
                amount: discount.toFixed(2),
            },
            roundedTotal: getNum(roundedTotal)
        }
    }
}

export const exportPdf = async (voucherId, fileName,setSaving) => {
    getVoucherPdf(voucherId)
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            setSaving(false)
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            
            
        });
}

export const createRandomString = (length) => {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const convertArrayToMap = (arr, key) => {
    const map = {};
    arr.forEach(item => {
        map[item[key]] = item;
    });
    return map;
}

export const isProdEnv = () => {
    const hostname = window.location.hostname;
    return hostname.includes("app.khatabuddy") || hostname.includes("infinitynk");
}

const isPageInIframe = () => {
    return (window.location !== window.parent.location);
}

export const isWhitelistedForPg = () => {
    const hostname = window.location.hostname;
    const whiltelistedList = [
        "infinitynk.com",
        "app.dev.xp", // for testing/ dev
    ];
    if (isPageInIframe()) {
        return true; // to avoid infinite redirection
    }
    for (const domain of whiltelistedList) {
        if (hostname.includes(domain)) {
            return true;
        }
    }
    return false;
}

export const SUBSCRIPTIONS = {
    FREE: "free",
    LITE: "lite",
    PREMIUM: "premium",
};

export const planDurationsMap = {
    "1yr": "1 Year",
    "3yr": "3 Years"
};

export const planNamesMap = {
    "free": "Free Plan",
    "lite": "Lite Plan",
    "premium": "Elite Plan"
}

export const getPlan = (company) => {
    return _.get(company, "subscription.plan", SUBSCRIPTIONS.FREE);
}

export const sleep = (duration) => new Promise(resolve => setTimeout(resolve, duration));

export const isPuc = () => {
    const hostname = window.location.hostname;
    return hostname.includes("puc");
}

export const isErpWeb = () => {
    const hostname = window.location.hostname;
    return hostname.includes("erp");
}

export const pucRenewalPlans = [
    // {label: "3 Months", value: "3_months"},
    {label: "6 Months", value: "6_months"},
    {label: "1 Year", value: "1_years"},
];

export const pucRenewalPlansMap = convertArrayToMap(pucRenewalPlans, 'value');

export const isValidPhone = (phone) => {
    const isNumber = function(str) {return /^\d+$/.test(str);}
    return typeof phone === "string" && phone.length === 10 && isNumber(phone);
}

export const isValidNumber = (input, length) => {
    const isNumber = function(str) {return /^\d+$/.test(str);}
    return typeof input === "string" && input.length === length && isNumber(input);
}

export const isValidEmail = (str) => typeof str === "string" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str);

export const beautifyAmount = (amount = "") => {
    if (!amount) {
        return "0";
    }
    let minusPrefix = "";
    if (typeof amount === 'number' && amount < 0) {
        minusPrefix = "-";
        amount = -amount;
    } else if (typeof amount === "string" && amount.charAt(0) === "-") {
        minusPrefix = "-";
        amount = String(amount).slice(1);
    }
    if (typeof amount !== "string") {
        amount = amount.toString();
    }

    let [left, right] = amount.split(".");
    let finalStr = "";
    if (right) {
        finalStr = `.${right}`;
    }

    // manipulate left:
    let partLength = 3;
    while (left.length) {
        const len = partLength;
        let part;
        if (left.length > len) {
            part = left.slice(-len);
            left = left.slice(0, left.length - len);
        } else {
            part = left;
            left = ""
        }
        finalStr = `${part}${partLength<3 ? "," : ""}${finalStr}`;
        partLength = 2;
    }
    let finalAmount = minusPrefix + finalStr
    let indexOfDot = finalAmount.indexOf(".")
    if ( indexOfDot!== -1) {
        finalAmount = finalAmount.slice(0,indexOfDot+3)
    }
    return finalAmount;
}


export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        } finally {
            document.body.removeChild(textarea);
        }
    }
}

export const assignTableColsWidth = (cols) => {
    let weightSum = 0;
    cols.forEach(col => {
        weightSum += col.weight;
    });

    if (!weightSum) {
        return;
    }
    cols.forEach(col => {
        const current = (col.weight * 100) / weightSum;
        col.width = `${current.toFixed(2)}%`;
    });
}

export const defaultDocumentPrefixes = {
    invoice: "INV-",
    proforma: "PI-",
    receipt: "PAYIN-",
    payment: "PAYOUT-",
    expense: "PAYOUT-",
    creditNote: "CR-",
    debitNote: "DR-",
    purchase: "PINV-",
    delivery: "DC-",
}

const mediaQueryHook = useMediaQuery;

export const isMobileView = () => {
    const isPortrait = mediaQueryHook({ query: '(orientation: portrait)' });
    return isPortrait;
}

export const PublicShareLinks = {
    VOUCHER: "voucher",
    LEDGER: "ledger",
    STORE: "company",
    ECOMMERCE_ORDER: 'ecommerce'
}

export const getPublicShareLink = (type, hashId) => {
    const baseUrl = isProdEnv() ? `https://khatabuddy.in` : `${exportedUrls.baseUrl}/shortUrl`;
    let link = `${baseUrl}/?h=${hashId}`;
    if (!isProdEnv()) {
        link = link + `?host=${exportedUrls.origin}`;
    }
    return link;
}

export const requiredRule = (msg) => [{required: true, message: msg}];

export const iAssert = (condition, errMsg) => {
    if (!condition) {
        throw new ExternalError(errMsg);
    }
}