import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, message, Radio, Alert, Row, Col, Divider} from "antd";
import {Link} from "react-router-dom";
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import {PercentageOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {getPlans} from "../../services/api";
import {isPuc} from "../../utils";

const planDurationsMap = {
    "1yr": "1 Year",
    "3yr": "3 Years"
};

const planNamesMap = {
    "free": "Free Plan",
    "lite": "Premium",
    "premium": "Elite"
}

const SubscriptionManagement = () => {
    const state = useSelector(state => state.oldState);
    const [selectedDuration, setSelectedDuration] = useState("1yr");
    const [plans, setPlans] = useState({
        "lite": {
            "1yr": "--" ,
            "3yr": "--"
        },
        "premium": {
            "1yr": "--",
            "3yr": "--"
        }
    });
    let currentPlan = state?.company ? `${planNamesMap[state?.company?.subscription?.plan || "free"]}` : "--";
    if (state?.company?.subscription?.expiry) {
        currentPlan += ` (Validity: ${moment(state?.company?.subscription?.expiry).format("DD MMM YYYY")})`;
    }
    const showErr = (msg) => {
        message.info(msg);
    }

    useEffect(() => {
        if (state && state.company) {
            getPlans().then(resp => setPlans(resp.data.data.plans));
        }
    }, [state]);

    const kbPricingTable = <PricingTable highlightColor='#1976D2'>
        <PricingSlot
            // onClick={() => showErr("Your plan will be automatically downgraded to Free Plan after expiry")}
            // buttonText={"Downgrade to Free"}
            shouldDisplayButton={false}
            title='Free Forever' priceText='₹0 for ever'>
            <PricingDetail>Unlimited Invoices / Quotations</PricingDetail>
            <PricingDetail>Single User</PricingDetail>
            {/*<PricingDetail>Unlimited users + Multiple Login</PricingDetail>*/}
            <PricingDetail>Track Payments & Expenses</PricingDetail>
            <PricingDetail>Unlimited Customers & Vendors</PricingDetail>
            <PricingDetail>Multiple Invoice Themes</PricingDetail>
            <PricingDetail>Payment Reminders to Customers</PricingDetail>
            <PricingDetail>Whatsapp & Email Share Options</PricingDetail>
            <PricingDetail>Inventory Management</PricingDetail>
            <PricingDetail>UPI QR Code on Invoice</PricingDetail>
            <PricingDetail>GST Reports</PricingDetail>
            <PricingDetail>
                {/*<Link>*/}
                    <Button disabled={true} style={{margin: 16, height: 40, borderRadius: 200, width: '80%', fontSize: 18}} type={"primary"}>(Already Active)</Button>
                {/*</Link>*/}
            </PricingDetail>
            {/*<PricingDetail>*/}
            {/*<Button style={{marginTop: 32}} type={"primary"}>Test Button</Button>*/}
            {/*</PricingDetail>*/}
        </PricingSlot>
        <PricingSlot
            // highlighted
            // onClick={() => showErr("You can renew plan after 15 Mar 2022")} buttonText='Renew'
            shouldDisplayButton={false}
            title='Premium'
            priceText={`₹${plans["lite"][selectedDuration]} for ${planDurationsMap[selectedDuration]}`}
        >
            <PricingDetail>Everything in Free +</PricingDetail>
            <PricingDetail>Unlimited Users</PricingDetail>
            <PricingDetail>No KhataBuddy branding on docs</PricingDetail>
            <PricingDetail>Collect Online Payments</PricingDetail>
            <PricingDetail>Campaigns on SMS & Whatsapp</PricingDetail>
            <PricingDetail>Daily Business Reports</PricingDetail>
            <PricingDetail>Automated Payment Reminders</PricingDetail>
            <PricingDetail>Analytics</PricingDetail>
            <PricingDetail>Online Ecommerce Store</PricingDetail>
            <PricingDetail>Bulk Import Data</PricingDetail>
            <PricingDetail>GSTIN Validation</PricingDetail>
            <PricingDetail>Priority Support</PricingDetail>

            <PricingDetail>
                <Link to={`/settings/subscription/lite_${selectedDuration}`}>
                    <Button style={{margin: 16, height: 40, borderRadius: 200, width: '80%', fontSize: 18}} type={"primary"}>Buy Now</Button>
                </Link>
            </PricingDetail>
        </PricingSlot>
        <PricingSlot onClick={() => showErr("To be launched soon")} buttonText='Upgrade to Elite'
                     title='Elite'
                     shouldDisplayButton={false}
                     priceText={`₹${plans["premium"][selectedDuration]} for ${planDurationsMap[selectedDuration]}`}>
            <PricingDetail>Everything in Premium +</PricingDetail>
            <PricingDetail>Unlimited Users</PricingDetail>
            <PricingDetail>E-way Bills</PricingDetail>
            <PricingDetail>E-invoicing</PricingDetail>
            <PricingDetail>Subscription (Re-curring invoicing)</PricingDetail>
            <PricingDetail>Multi Currency</PricingDetail>
            <PricingDetail>Barcodes Printing</PricingDetail>
            <PricingDetail>Export Data for TALLY</PricingDetail>
            <PricingDetail>Export JSON for GST Portal</PricingDetail>
            <PricingDetail>Banking Reconcilation</PricingDetail>
            <PricingDetail>GSTR-2A Mismatch Report</PricingDetail>
            <PricingDetail>
                <Link to={`/settings/subscription/premium_${selectedDuration}`}>
                    <Button style={{margin: 16, height: 40, borderRadius: 200, width: '80%', fontSize: 18}} type={"primary"}>Buy Now</Button>
                </Link>
            </PricingDetail>
        </PricingSlot>
    </PricingTable>;

    const pucPricingTable = <PricingTable highlightColor='#1976D2'>
        <PricingSlot
            // onClick={() => showErr("Your plan will be automatically downgraded to Free Plan after expiry")}
            // buttonText={"Downgrade to Free"}
            shouldDisplayButton={false}
            title='Free Forever' priceText='₹0 for ever'>
            <PricingDetail>up to <b>15</b> records /month</PricingDetail>
            <PricingDetail><b>Manual</b> SMS reminders to customers</PricingDetail>
            <PricingDetail><b>Limited</b> Support</PricingDetail>
            {/*<PricingDetail>*/}
            {/*<Button style={{marginTop: 32}} type={"primary"}>Test Button</Button>*/}
            {/*</PricingDetail>*/}
        </PricingSlot>
        <PricingSlot
            // highlighted
            // onClick={() => showErr("You can renew plan after 15 Mar 2022")} buttonText='Renew'
            shouldDisplayButton={false}
            title='Premium'
            priceText={`₹${plans["lite"][selectedDuration]} for ${planDurationsMap[selectedDuration]}`}
        >
            <PricingDetail>up to <b>5,000</b> records /year</PricingDetail>
            <PricingDetail><b>Automatic</b> SMS reminders to customers</PricingDetail>
            <PricingDetail><b>Priority</b> Support</PricingDetail>
            <PricingDetail>
                <Link to={`/settings/subscription/lite_${selectedDuration}`}>
                    <Button style={{margin: 16, height: 40, borderRadius: 200, width: '80%', fontSize: 18}} type={"primary"}>Buy Now</Button>
                </Link>
            </PricingDetail>
        </PricingSlot>
        <PricingSlot onClick={() => showErr("To be launched soon")} buttonText='Upgrade to Elite'
                     title='Elite'
                     shouldDisplayButton={false}
                     priceText={`₹${plans["premium"][selectedDuration]} for ${planDurationsMap[selectedDuration]}`}>
            <PricingDetail>up to <b>25,000</b> records /year</PricingDetail>
            <PricingDetail><b>Automatic</b> SMS reminders to customers</PricingDetail>
            <PricingDetail><b>Priority</b> Support</PricingDetail>
            <PricingDetail>
                <Link to={`/settings/subscription/premium_${selectedDuration}`}>
                    <Button style={{margin: 16, height: 40, borderRadius: 200, width: '80%', fontSize: 18}} type={"primary"}>Buy Now</Button>
                </Link>
            </PricingDetail>
        </PricingSlot>
    </PricingTable>;

    return (
        <div>
            <h2>Subscription</h2>
            <Row align={"middle"} justify={"center"}>
                <Col span={2}>Current Plan</Col>
                <Col span={22}>
                    <Alert style={{width: 'fit-content'}} message={currentPlan}/>
                </Col>
            </Row>
            <Divider/>
            {/*<Result*/}
            {/*    status="success"*/}
            {/*    title={<>Current Subscription: Premium</>}*/}
            {/*    subTitle="Status: Active | Validity: 31 Mar, 2022"*/}
            {/*    extra={[*/}
            {/*        <Link to={"/dashboard"}><Button type="primary" key="console">*/}
            {/*            Go To Dashboard*/}
            {/*        </Button></Link>,*/}
            {/*        <Tooltip title={"You can renew plan after 15 Mar 2022"}><Button disabled>Renew Subscription</Button></Tooltip>,*/}
            {/*    ]}*/}
            {/*/>*/}
            <h2>Plans & Pricing</h2>
            <Alert type={"success"} message={"Referral or offer coupons can be applied on checkout page."} icon={<PercentageOutlined />} showIcon/>
            <div style={{padding: 8, textAlign: 'center'}}>
                <Radio.Group defaultValue="1yr" buttonStyle="solid" onChange={e => setSelectedDuration(e.target.value)}>
                    <Radio.Button value="1yr">1 Year Plan</Radio.Button>
                    <Radio.Button value="3yr">3 Years Plan</Radio.Button>
                </Radio.Group>
            </div>
            {
                isPuc() ? pucPricingTable : kbPricingTable
            }
        </div>
    )
};

export default SubscriptionManagement;
