import React from "react";
import {Col, Row, Button} from "antd";
import {PRIMARY_COLOR, RUPEE} from "../../../models/constants";
import {Stepper} from "antd-mobile";
import {DeleteFilled} from "@ant-design/icons";
import { StoreImage } from "./storeImage";

const CartItem = ({item, remove, modify, disabled}) => {


    const RightSide = () => <>
        <h3 style={{fontWeight: 'bold', margin: 0}}>{item.name}</h3>
        <div style={{fontWeight: 'bold', fontSize: 16}}>
            {RUPEE} {item.defaultRate}
        </div>
        <div style={{ color: 'red', fontSize: 12 }}>
            {!disabled ? (<Button type="text" danger icon={<DeleteFilled />} size="small" onClick={remove}>
                Remove
            </Button>) : ""}
             
        </div>
    </>;

    return (
        <Col span={24} style={{padding: 16, border: '0px solid red'}}>
            <Row>
                <Col span={18}>
                    <Row>
                        <Col>
                        <StoreImage image={`/api/internal/file/${item.img}`} fallback="https://vyaparwebsiteimages.vypcdn.in/catalogue/product-placeholder.png"/>
                        </Col>
                        <Col style={{paddingLeft: 16}}>
                            <RightSide/>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <Stepper
                        style={{border: '2px solid ' + PRIMARY_COLOR, borderRadius: 200, float: 'right'}}
                        defaultValue={item.qty}
                        min={1}
                        onChange={(qty)=>{
                            item.qty = qty
                            modify(item)
                        }}
                        disabled={!!disabled}
                    />
                </Col>
            </Row>
        </Col>
    )
};

export default CartItem;
