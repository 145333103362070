import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, message, Row, Select, Switch, Tooltip} from "antd";
import {saveCompany} from "../../services/api";
import _ from "lodash";
import {useSelector} from "react-redux";
import InvoiceTemplates from "../rightDrawer/invoiceTemplates";
import {StarOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {currenciesMap} from "../../models/constants";

const CustomSwitch = ({value, onChange, label}) => {
    return (
        <>
            <Switch checked={value} onChange={(val) => onChange(val)}>ABCD</Switch>
            &nbsp;{label}
        </>
    )
}

const InvoiceSettings = () => {
    const [form] = Form.useForm();
    const state = useSelector(state => state.oldState);
    const [config, setConfig] = useState({});
    const [visible, setVisible] = useState(false);
    const [enableQr, setEnableQr] = useState(false);

    useEffect(() => {
        if (state.company) {
            if (!state.company.invoiceConfig.defaultCurrency) {
                state.company.invoiceConfig.defaultCurrency = "inr";
            }
            setConfig(state.company?.invoiceConfig || {});
        }
    }, [state]);

    const isPlanUpgraded = ["elite", "premium"].includes(state?.company?.subscription?.plan);

    useEffect(() => {
        form.setFieldsValue(config);
        setEnableQr(config.enableQR || false);
    }, [config, form]);

    return (
        <div>
            {/*<h2>Invoice Settings</h2>*/}
            <Button className={"btnCustom2"} type={"primary"} onClick={() => {
                setVisible(true);
            }}>
                <StarOutlined /> Invoice Templates
            </Button>

            <Divider/>

            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                initialValues={{}}
            >
                {/*<h3>Options</h3>*/}
                <div>
                    <Row>
                        <Col span={12}>
                            <Form.Item name={"enableLogo"}>
                                <CustomSwitch label={"Show Business logo"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"enableSign"}>
                                <CustomSwitch label={"Show Signature"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"enableBank"}>
                                <CustomSwitch label={"Show Bank Details"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"enableShipping"}>
                                <CustomSwitch label={"Show Shipping Address"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"enableTnc"}>
                                <CustomSwitch label={"Show Terms & Conditions"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"enablePaymentReceived"}>
                                <CustomSwitch label={"Show Payment Received & Balance"}/>
                            </Form.Item>
                        </Col>
                        {
    isPlanUpgraded && 
    <Col span={12} className={"hideBranding"}>
                            <Form.Item name={"hideBranding"}>
                                <CustomSwitch label={"Remove KhataBuddy Branding"} />
                            </Form.Item>
                        </Col>
                        
}

                        <Col span={12}>
                            <Form.Item name={"enableQR"}>
                                <CustomSwitch onChange={setEnableQr} label={"Show UPI QR Code"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"enableTaxBifurcation"}>
                                <CustomSwitch label={
                                    <>
                                        Show Tax Bifurcation
                                        <Tooltip title={"Show Rate-wise breakup of total tax on invoice"}>
                                            <InfoCircleOutlined style={{ marginLeft: 5 }} />
                                        </Tooltip>
                                    </>
                                }/>
                            </Form.Item>
                        </Col>

                        
                        <Col span={12} className={"sidePads"}>
                            <Form.Item name={"defaultCurrency"} label={"Currency"} className={"sidePads"}>
                                <Select
                                    placeholder={"Select Currency"}
                                    options={_.map(currenciesMap, c => ({
                                        label: `${c.name} (${c.symbol})`,
                                        value: c.id
                                    }))}
                                    filterOption={(input, option) => {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >=0 ||
                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >=0
                                    }}
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        {
                            enableQr &&
                        <>
                            <Col span={12} className={"sidePads"}>
                                <Form.Item label={"UPI ID"} name={"upiId"} className={"sidePads"}>
                                    <Input placeholder={"Receiver's UPI ID"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12} className={"sidePads"}>
                                <Form.Item name={"upiAmountType"} label={"Amount"} className={"sidePads"}>
                                    <Select
                                        placeholder={"Amount selection to generate QR"}
                                        options={[{label: "Fixed to Invoice Value", value: "invoice"}, {
                                            label: "Variable (User can pay any amount)",
                                            value: "generic"
                                        }]}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                        }


                    </Row>
                </div>
                <br/>
                {/*<h3>Labels</h3>*/}
                <div>
                    <Form.Item name={"tnc"} label={"Terms & Conditions"}>
                        <Input.TextArea style={{height: 100}}/>
                    </Form.Item>
                </div>
                <Button className={"customPrimary"} type={"primary"} onClick={() => {
                    form.validateFields().then(async () => {
                        const obj = form.getFieldsValue();
                        console.log("## Obj ##", obj);
                        if (obj.enableQR && (!obj.upiId || !obj.upiAmountType)) {
                            message.error("Please input UPI ID & select Amount type to enable UPI QR on invoice");
                            return;
                        }
                        const company = _.cloneDeep(state?.company || {});
                        if (!company.invoiceConfig) {
                            company.invoiceConfig = {};
                        }
                        _.assign(company.invoiceConfig, obj);
                        await saveCompany(company);
                        message.success("Document Settings saved successfully!");
                    }).catch(() => {
                    });
                }}>Save Settings</Button>
            </Form>

            <InvoiceTemplates visible={visible} setVisible={setVisible}/>
        </div>
    )
};

export default InvoiceSettings;
