/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Image, Spin } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getInternalVouchers } from "../../services/api";
import logo from "../../res/img/logoLandscapeV3.png";
import "./linkstyle.css";
import {
  beautifyAmount,
  exportPdf,
  isMobileView,
  isValidPhone,
  customDispatcher,
} from "../../utils";
import { PhoneOutlined } from "@ant-design/icons";
import { WhatsAppOutlined } from "@ant-design/icons";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";

import { Button, Tag } from "antd";
import { Col, Row } from "antd";
import { RUPEE } from "../../models/constants";
import { useParams } from "react-router-dom";
import { voucherTypes } from "../../models/constants";

const moment = require("moment");

function ShareVoucher() {
  const navigate = useNavigate();
  const dispatch = customDispatcher(useDispatch());

  const [voucher, setVoucher] = useState({});
  const [Loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const params = useParams();
  const { voucherId } = params || {};
  const isMobile = isMobileView();
  let col5 = 7;
  let col14 = 10;
  if (isMobile) {
    col5 = 0;
    col14 = 24;
  }

  useEffect(async () => {
    let x = await getInternalVouchers(voucherId);
    setVoucher(x.data.data.vouchers[0]);
    setLoading(false);
  }, []);

  const OnRedirect = (data) => {
    navigate(`/pay/${data}/gateway`);
  };

  let status;
  const balance = voucher.amount - (voucher.paid || 0);
  if (balance === voucher.amount) {
    status = "Pending";
  } else if (balance < 0) {
    status = "Over Paid";
  } else if (balance > 0) {
    status = "Partially Paid";
  } else {
    status = "Paid";
  }

  const colorMap = {
    Pending: "red",
    "Partially Paid": "orange",
    Paid: "green",
    "Over Paid": "cyan",
  };

  return Loading ? (
    <div className={"splash"}>
      <Image width={isMobile ? 200 : 300} src={logo} />
      <br />
      <br />
      <Spin size="large" />
    </div>
  ) : (
    <>
      <Row style={{ margin: "12px" }}>
        <Col span={col5}></Col>
        <Col span={col14}>
          <div className="background">
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Hi {voucher.partyName} !
            </p>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              {voucherTypes[voucher.type].publicLinkName} from{" "}
              {voucher.companyDetails.name}!
            </p>
            {voucher.type === "invoice" && (
              <Tag color={colorMap[status]} style={{ borderRadius: "1px" }}>
                {status}
              </Tag>
            )}

            <div style={{ width: "58%" }}>
              <div className="tablechild" style={{ justifyContent: "center" }}>
                <Button
                  className={"btnCustom4 hoverOrangeBorder borderLightGrey"}
                  onClick={(e) => {
                    const phoneString = isValidPhone(voucher?.party?.phone)
                      ? `${voucher?.party?.phone}`
                      : "";
                    const waLink = `tel://${phoneString}`;
                    window.open(waLink, "_blank");
                  }}
                  style={{
                    background: "#ffecb3",
                    color: "#ff6f00",
                    padding: 0,
                    height: 28,
                    fontWeight: "bold",
                    fontSize: 13,
                    paddingLeft: 8,
                    paddingRight: 8,
                    marginTop: 12,
                    justifyContent: "space-around",
                  }}
                >
                  <PhoneOutlined /> Call
                </Button>
                &nbsp;&nbsp;
                <Button
                  className={"btnCustom4 borderLightGrey"}
                  onClick={(e) => {
                    const phoneString = isValidPhone(voucher?.party?.phone)
                      ? `91${voucher?.party?.phone}`
                      : "";
                    const waLink = `https://api.whatsapp.com/send/?phone=${phoneString}&text=${encodeURI(
                      "Hi"
                    )}`;
                    window.open(waLink, "_blank");
                  }}
                  style={{
                    background: "#e8f5e9",
                    color: "#1b5e20",
                    padding: 0,
                    height: 28,
                    fontWeight: "bold",
                    fontSize: 13,
                    paddingLeft: 8,
                    paddingRight: 8,
                    marginTop: 12,
                    justifyContent: "space-around",
                  }}
                >
                  <WhatsAppOutlined /> Whatsapp
                </Button>
              </div>
            </div>

            <div
              style={{ textAlign: "center", color: "gray", marginTop: "14px" }}
            >
              {" "}
              Total Amount
            </div>
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "25px",
                margin: -6,
              }}
            >
              {" "}
              {RUPEE} {beautifyAmount(voucher.amount)}{" "}
            </div>

            <div style={{ width: "68%" }}>
              <div className="tablechild">
                <p>{voucherTypes[voucher.type].filePrefix} #:</p>
                <p style={{ textAlign: "right" }}>{voucher.displayDocNum}</p>
              </div>
              <div className="tablechild">
                <p>{voucherTypes[voucher.type].filePrefix} Date:</p>
                <p style={{ textAlign: "right" }}>
                  {moment(voucher.date).format("DD MMM YYYY")}
                </p>
              </div>
              {voucher.type === "invoice" && (
                <div className="tablechild">
                  <p>Balance :</p>
                  <p style={{ textAlign: "right" }}>
                    {RUPEE} {beautifyAmount(balance)}
                  </p>
                </div>
              )}
            </div>
            <div
              style={{
                width: "68%",
                display: "flex",
                justifyContent: voucher.companyDetails?.pgConfig?.enabled ? "space-between" : "center",
              }}
            >
              <Button
                type="primary"
                shape="round"
                onClick={(e) => {
                  setSaving(true);
                  exportPdf(voucherId, "invoice.pdf", setSaving);
                }}
                loading={saving}
              >
                Download {voucherTypes[voucher.type].filePrefix}{" "}
                {voucher.type === "proforma" && "Invoice"}
                <VerticalAlignBottomOutlined />
              </Button>
              {(voucher.amount > 0 && (voucher && voucher.companyDetails?.pgConfig?.enabled)) && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    dispatch({
                      popData: {
                        visible: true,
                        placement: "bottom",
                        height: 200,
                        type: "payNow",
                        title: "Make Payment",
                        data: {
                          payAmount: voucher.amount,
                          ledgerId: voucher.party._id,
                          voucherId: voucher._id,
                        },
                        onRedirect: OnRedirect,
                      },
                    });
                  }}
                >
                  Pay Now
                </Button>
              )}
            </div>
          </div>
        </Col>
        <Col span={col5}></Col>
      </Row>

      <div style={{ textAlign: "center", marginTop: 32 }}>
        Powered By&nbsp;&nbsp;
        <Image style={{ height: 24, width: 113 }} preview={false} src={logo} />
      </div>
    </>
  );
}

export default ShareVoucher;
