export const EDocumentTypes = {
    VOUCHER: "VOUCHER",
    LEDGER: "LEDGER",
}

export const EShareModes = {
    WHATSAPP: "WHATSAPP",
    EMAIL: "EMAIL",
    SMS: "SMS",
}

