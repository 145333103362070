import moment from "moment";
import {Button, message, Popconfirm, Tag, Tooltip, Popover, Alert, Menu, Dropdown} from "antd";
import _ from "lodash";
import {
    BellOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    LinkOutlined, MailFilled, MessageFilled,
    ShareAltOutlined,
    WhatsAppOutlined,
    DollarOutlined,
    CopyOutlined,
    ForwardOutlined
} from "@ant-design/icons";
import React from "react";
import {beautifyAmount, copyToClipboard, getPublicShareLink, isValidPhone, PublicShareLinks} from "../../utils";
import {getLedgers, sendSMS} from "../../services/api";
import {PRIMARY_COLOR, RUPEE, voucherTypes} from "../../models/constants";
import { ButtonV2 } from "../../components/ButtonV2";

export const saleColumns = (docType, filterInvoice, voucherType, filterDate, filterClient, deleteVoucher, fetchRecords, showPaymentTooltip, setShowPaymentTooltip, company, dispatch, navigate) => [
    
    {
        include: ["invoice", "proforma", "receipt", "purchase", "expense", "payment", "delivery", "creditNote", "debitNote"],
        title: voucherType === "invoice" ? 'Bill #' : "Doc #",
        width: '11%',
        render: ({documentNumber: obj, externalDocumentNumber}) => ["purchase", "debitNote"].includes(voucherType) ? externalDocumentNumber :
            (docType.idType !== "mixed" ? obj : `${obj.prefix}${obj.num}`),
        filteredValue: [filterInvoice],
        onFilter: (value, record) => {
            const docNum = (docType.idType !== "mixed") ?
                record.documentNumber :
                `${record.documentNumber.prefix || ""}${record.documentNumber.num}`;
            return docNum?.toLowerCase?.().includes(value.toLowerCase()) ||
                record.partyName?.toLowerCase().includes(value.toLowerCase());
        },
        sorter: (a, b) => {
            const aPrefix = a.documentNumber.prefix || "";
            const aNum = a.documentNumber.num;
            const bPrefix = b.documentNumber.prefix || "";
            const bNum = b.documentNumber.num;
            if (aPrefix === bPrefix) {
                return aNum - bNum;
            }
            return aPrefix.localeCompare(bPrefix);
        },
        defaultSortOrder: "descend"
    },
    {
        include: ["invoice", "proforma", "receipt", "purchase", "expense", "payment", "delivery", "creditNote", "debitNote"],
        title: "Date",
        width: '10%',
        render: (obj) => moment(obj.date).format('DD MMM YYYY'),
        filteredValue: ["ABC"],
        onFilter: (value, record) => {
            const [start, end] = filterDate || [];
            if (start && end) {
                return moment(record.date).isBetween(start.startOf('day'), end.endOf('day'));
            }
            return true;
        },
        sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
    },
    {
        include: ["invoice", "proforma", "receipt", "purchase", "expense", "payment", "delivery", "creditNote", "debitNote"],
        title: ["purchase", "debitNote"].includes(voucherType) ? "Vendor" : 'Customer',
        width: '17%',
        render: (voucher) => <>
            <b>{voucher?.party?.name}</b>
            <br/>
            <div style={{fontSize: 11, color: 'grey'}}>
                {voucher?.party?.phone}
            </div>
        </>,
        // filteredValue: [filterClient],
        // onFilter: (value, record) => record.partyName?.toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => a.partyName?.localeCompare(b.partyName)
    },
    {
        
        include: ["receipt"],
        title: 'Client Name',
        dataIndex: 'crPartyName',
        filteredValue: [filterClient],
        onFilter: (value, record) => record.crPartyName?.toLowerCase().includes(value.toLowerCase()),
        sorter: (a, b) => a.crPartyName?.localeCompare(b.crPartyName)
    },
    {
        include: ["invoice", "proforma", "receipt", "purchase", "expense", "payment", "delivery", "creditNote", "debitNote"],
        title: 'Amount',
        width: '10%',
        render: ({amount}) => <b style={{color: 'green'}}>₹ {beautifyAmount(amount)}</b>,
        sorter: (a, b) => a.amount - b.amount
    },
    {
        include: ["invoice"],
        title: "Status",
        width: '13%',
        render: (voucher) => {
            const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, voucher?.hashId);
            const invoiceNum = docType.idType !== "mixed" ? voucher.documentNumber : `${voucher.documentNumber.prefix}${voucher.documentNumber.num}`;
            const msgContent = `Gentle Reminder, your payment of ₹${voucher.amount} is pending at ${company?.name} under ${invoiceNum}. Click here: ${publicLink} to view the details and make the payment. - Sent using KhataBuddy`;
            let status;
            const balance = voucher.amount - (voucher.paid || 0);
            if (balance === voucher.amount) {
                status = "Pending";
            } else if (balance < 0) {
                status = "Over Paid";
            } else if (balance > 0) {
                status = "Partially Paid";
            } else {
                status = "Paid";
            }

            const colorMap = {
                "Pending": "red",
                "Partially Paid": "orange",
                "Paid": "green",
                "Over Paid": "cyan"
            };

            return <>
                <Tag color={colorMap[status]}>{status}</Tag>
                {
                    balance > 0 &&
                    <Popover
                        content={
                            <div onClick={e => e.stopPropagation()}>
                                <Alert type={"info"} style={{background: 'white', border: 0, width: 500}}
                                       showIcon={false} message={
                                    <>
                                        <div>
                                            {msgContent}
                                        </div>
                                        <br/>
                                        <ButtonV2 type={"primary"} style={{fontSize: 12, padding: 4, height: 26}}
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    if (!isValidPhone(voucher?.party?.phone)) {
                                                        message.error(`Customer contact is not a valid mobile number`);
                                                        return;
                                                    }
                                                    console.log("voucher", voucher)
                                                    console.log("date", typeof(voucher?.date))
                                                    const resp = await sendSMS({
                                                        origin: "EXTERNAL_KB_APP",
                                                        phone: voucher?.party?.phone,
                                                        smsTemplate: "KB_WHATSAPP_PENDING_PAYMENT",
                                                        params: {
                                                            customerName:voucher?.party?.name || " ",
                                                            amount: `${RUPEE} ${voucher.amount}`,
                                                            merchant: company?.name || "",
                                                            invoiceNum: invoiceNum,
                                                            url: voucher?.hashId,
                                                            dated:moment(voucher?.date)?.format?.("DD MMM YYYY") || " "
                                                        }
                                                    },"whatsapp");
                                                    if (!resp.data.success) {
                                                        message.error(resp.data.message);
                                                    } else {
                                                        message.success("Reminder sent on Whatsapp successfully!");
                                                    }
                                                }}>
                                            Send on Whatsapp
                                        </ButtonV2>
                                        &nbsp;&nbsp;
                                        <ButtonV2 type={"primary"} style={{fontSize: 12, padding: 4, height: 26}}
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    if (!isValidPhone(voucher?.party?.phone)) {
                                                        message.error(`Customer contact is not a valid mobile number`);
                                                        return;
                                                    }
                                                    const resp = await sendSMS({
                                                        origin: "EXTERNAL_KB_APP",
                                                        phone: voucher?.party?.phone,
                                                        smsTemplate: "KB_PENDING_PAYMENT",
                                                        params: {
                                                            amount: `Rs. ${voucher.amount}`,
                                                            merchant: company?.name || "",
                                                            invoiceReference: invoiceNum,
                                                            url: publicLink,
                                                        }
                                                    },"sms");
                                                    if (!resp.data.success) {
                                                        message.error(resp.data.message);
                                                    } else {
                                                        message.success("Reminder SMS sent successfully!");
                                                    }
                                                }}
                                        >

                                            Send on SMS
                                        </ButtonV2>
                                    </>
                                }/>
                            </div>
                        }
                        title="" trigger="click" onVisibleChange={(visible) => setShowPaymentTooltip(!visible)}>

                        <Tooltip
                            // popupVisible={showPaymentTooltip}
                            title={"Send Reminder"}>
                            <Button style={{
                                fontSize: 12,
                                padding: 0,
                                margin: 0,
                                height: 22,
                                color: 'white',
                                background: PRIMARY_COLOR,
                                paddingLeft: 6,
                                paddingRight: 6,
                                border: 0,
                                borderRadius: 4
                            }} onClick={(e)=>{
                                e.stopPropagation();
                            }}>
                                <BellOutlined style={{}}/>
                            </Button>
                        </Tooltip>
                    </Popover>
                }
                &nbsp;
                {
                   balance > 0 && <Tooltip
                    // popupVisible={showPaymentTooltip}
                    title={"Record payment"}>
                    <Button style={{
                        fontSize: 12,
                        padding: 0,
                        margin: 0,
                        height: 22,
                        color: 'white',
                        background: PRIMARY_COLOR,
                        paddingLeft: 6,
                        paddingRight: 6,
                        border: 0,
                        borderRadius: 4
                    }} 
                    onClick={async (e)=>{
                        const banks = (await getLedgers("cash"))?.data?.data?.ledgers;
                        const props_data = {
                            entryType : "Invoice-Wise",
                            ledgerCr : voucher?.ledgerDr || voucher?.ledgerDr || "",
                            ledgerDr:banks[0]._id,
                            smsEnabled:true,
                            distribution: [{voucherId: voucher?._id, amount:voucher.amount-(voucher?.paid || 0)}]
                        }
                        dispatch({
                            popData: {
                                visible: true,
                                type: "receiptWrite",
                                title: "Record Payment",
                                data: props_data,
                                onSave: fetchRecords
                                
                            }
                        });
                        e.stopPropagation();
                        console.log(voucher,"voucher------------")
                        // need to fetch the data of row 
                        // pass those data as props to right drawer
                    }}>
                        <DollarOutlined style={{}}/>
                        
                        
                    </Button>
                </Tooltip>                
                }
               
            </>;
        }
    },
    {
        include: ["invoice"],
        title: "Receipts",
        width: '10%',
        render: (voucher) => {
            return _.uniqBy(voucher.receipts || [], '_id').filter(r => !!r?.amount).map(r => (
                <Button style={{
                    fontSize: 12,
                    padding: 0,
                    margin: 0,
                    height: 22,
                    // color: 'white',
                    background: 'skyblue',
                    paddingLeft: 6,
                    paddingRight: 6,
                    border: 0,
                    borderRadius: 4,
                    marginRight: 4,
                    marginTop: 4,
                }} onClick={e => {
                    e.stopPropagation();
                    console.log("## Rec ##", r);
                    dispatch({
                        popData: {
                            visible: true,
                            type: "invoice",
                            title: `Payment Receipt`,
                            data: r,
                        }
                    })
                }}>
                    {`${RUPEE} ${r?.amount}`}
                </Button>
            ));
        }
    },
    {
        include: ["invoice", "proforma", "receipt", "purchase", "expense", "payment", "delivery", "creditNote", "debitNote"],
        title: 'Actions',
        width: '26%',
        render: (voucher) => {
            const companyName = company?.name
            const actionMap = {
                "invoice": "your purchase at "+companyName,
                "proforma": "showing interest with "+companyName,
                "receipt": "making payment at "+companyName,
            }
            const action = actionMap[voucher?.type] || ("doing business with "+companyName)
            const obj = voucher;
            const publicLink = getPublicShareLink(PublicShareLinks.VOUCHER, voucher?.hashId);
            const invoiceNum = docType.idType !== "mixed" ? voucher.documentNumber : `${voucher.documentNumber.prefix}${voucher.documentNumber.num}`;
            let msgContent = <>Hi {voucher?.party?.name || ""},
                <br/>
                Thank you for your purchase at {company?.name || ""}.
                <br/>
                Invoice No: {invoiceNum}
                <br/>
                Total: {voucher?.amount || ""}
                <br/>
                Invoice Link: {publicLink}
                <br/>
                - Sent using KhataBuddy
            </>;
            let whatsappMsgContent = <>Hi {voucher?.party?.name}
            <br/>
            Thank you for {action},
            <br />
            Here is your {voucherTypes[voucherType]?.name || "Document"}
            <br />
            Total Amount: {RUPEE} {voucher.amount}
            <br />
            </>;
            if (voucherType === "proforma") {
                msgContent = <>Hi {voucher?.party?.name}
                    <br/>
                    Thank you for showing your interest at {company?.name || ""}.
                    <br/>
                    Here is your Proforma Invoice: {publicLink}
                    <br/>
                    - Sent using KhataBuddy</>;
            } else if (voucherType === "receipt") {
                msgContent = <>Hi {voucher?.crParty?.name}
                    <br/>Thank you for making payment of {RUPEE} {beautifyAmount(voucher.amount)} at {company?.name || ""}.
                    <br/>Here is your Payment Receipt: {publicLink}
                    <br/>- Sent using KhataBuddy</>;
            }

            return (
                <div style={{fontSize: 20}}>
                    {
                        ["invoice", "proforma", "receipt", "delivery", "creditNote", "debitNote"].includes(voucherType) &&
                            <>
                        <Button className={"btnCustom3"}
                                style={{
                                    padding: 0,
                                    height: 28,
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    background: '#e8eaf6',
                                    color: '#283593'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    const titleMap = {
                                        "invoice": "Preview Invoice",
                                        "proforma": "Preview Proforma",
                                        "receipt": "Preview Receipt",
                                    }

                                    dispatch({
                                        popData: {
                                            visible: true,
                                            type: "previewInvoice",
                                            title: titleMap[voucherType] || "Preview", // `Send Invoice ${invoiceNum} on Email`,
                                            data: {
                                                voucherId: voucher?._id,
                                                type: voucherType,
                                            },
                                        }
                                    })
                                }}
                        ><EyeOutlined/> View
                        </Button>
                                {
                                    ["invoice", "proforma"].includes(voucherType) &&
                                    <>
                                &nbsp;
                                <Dropdown overlay={
                                    <Menu className={"menuWrapper"} onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                    }}>
                                        <Menu.Item className={"customHover"} onClick={() => {
                                            // e.stopPropagation();
                                            dispatch({
                                                popData: {
                                                    visible: true,
                                                    type: "email",
                                                    title: `Send ${docType.name} ${invoiceNum} on Email`,
                                                    data: voucher,
                                                }
                                            })
                                        }}>
                                            <div style={{fontWeight: 'bold', width: '100%'}} onClick={e => {
                                            }}>
                                                <MailFilled style={{fontSize: 18, color: 'red'}}/>&nbsp;&nbsp;Email
                                            </div>
                                        </Menu.Item>
                                        {/* <Popover
                                            style={{width: '100%'}}
                                            content={
                                                <div onClick={e => e.stopPropagation()}>
                                                    <Alert type={"info"} style={{background: 'white', border: 0, width: 500}}
                                                           showIcon={false} message={
                                                        <>
                                                            <div>
                                                                {whatsappMsgContent}
                                                            </div>
                                                            <br/>
                                                            <ButtonV2 type={"primary"} style={{fontSize: 12, padding: 4, height: 26}}
                                                                    onClick={async () => {
                                                                        // e.stopPropagation();
                                            const party = voucherType === "receipt" ? voucher.crParty : voucher.party;
                                            console.log("party",party)
                                            const phoneString = isValidPhone(party?.phone) ? `${party?.phone}` : "";
                                            if (!isValidPhone(phoneString)) {
                                                message.error(`Customer contact is not a valid mobile number`);
                                                return;
                                            }
                                            console.log("voucher", voucher)
                                            
                                            
                                            const resp = await sendSMS({
                                                
                                                origin: "EXTERNAL_KB_APP",
                                                phone: phoneString,
                                                smsTemplate: "KB_WHATSAPP_SEND_VOUCHER",
                                                params: {
                                                    voucherLink:"https://app.khatabuddy.com/api/internal/pdf/"+voucher?._id,
                                                    documentType:voucherTypes[voucherType]?.name || "Document",
                                                    customerName:party?.name || " ",
                                                    amount: `${RUPEE} ${voucher.amount}`,
                                                    url: voucher?.hashId,
                                                    action:action,
                                                    voucherFileName:"invoice.pdf"
                                                }
                                            },"whatsapp");
                                            if (!resp.data.success) {
                                                message.error(resp.data.message);
                                            } else {
                                                message.success("Document sent successfully!");
                                            }
                                            
                                                }}>
                                                                Send on whatsapp
                                                            </ButtonV2>
                                                        </>
                                                    }/>
                                                </div>
                                            }
                                            title="" trigger="click" onVisibleChange={(visible) => setShowPaymentTooltip(!visible)}> */}
                                            <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}} onClick={async (menuInfo) => {
dispatch({
    popData: {
        visible: true,
        type: "whatsappShare",
        title: `Send ${docType.name} ${invoiceNum} on Whatsapp`,
        data: {
            shareType: "Whatsapp",
            voucher,
        },
    }
})
                                            
                                        }}>
                                            <div style={{width: '100%'}}>
                                                <WhatsAppOutlined style={{color: 'green', fontSize: 18, cursor: 'pointer'}}/>
                                                &nbsp;&nbsp;Whatsapp
                                            </div>
                                        </Menu.Item>
                                    {/* </Popover> */}
        
                                        <Popover
                                            style={{width: '100%'}}
                                            content={
                                                <div onClick={e => e.stopPropagation()}>
                                                    <Alert type={"info"} style={{background: 'white', border: 0, width: 500}}
                                                           showIcon={false} message={
                                                        <>
                                                            <div>
                                                                {msgContent}
                                                            </div>
                                                            <br/>
                                                            <ButtonV2 type={"primary"} style={{fontSize: 12, padding: 4, height: 26}}
                                                                    onClick={async () => {
                                                                        if (!isValidPhone(voucher?.[voucherType==="receipt" ? "crParty" : "party"]?.phone)) {
                                                                            message.error(`Customer contact is not a valid mobile number`);
                                                                            return;
                                                                        }
                                                                        let resp;
                                                                        if (voucherType === "invoice") {
                                                                            resp = await sendSMS({
                                                                                origin: "EXTERNAL_KB_APP",
                                                                                phone: voucher?.party?.phone,
                                                                                smsTemplate: "KB_SEND_INVOICE",
                                                                                params: {
                                                                                    customerName: voucher?.party?.name || "customer",
                                                                                    merchant: company?.name || "",
                                                                                    invoiceNum,
                                                                                    amount: `Rs. ${voucher?.amount || ""}`,
                                                                                    url: publicLink,
                                                                                }
                                                                            },"sms");
                                                                        } else if (voucherType === "proforma") {
                                                                            resp = await sendSMS({
                                                                                origin: "EXTERNAL_KB_APP",
                                                                                phone: voucher?.party?.phone,
                                                                                smsTemplate: "KB_SEND_DOCUMENT",
                                                                                params: {
                                                                                    customerName: voucher?.party?.name || "customer",
                                                                                    action: `showing your interest`,
                                                                                    merchant: company?.name || "",
                                                                                    docWithLink: `${publicLink}`,
                                                                                }
                                                                            },"sms");
                                                                        } else if (voucherType === "receipt") {
                                                                            resp = await sendSMS({
                                                                                origin: "EXTERNAL_KB_APP",
                                                                                phone: voucher?.crParty?.phone,
                                                                                smsTemplate: "KB_SEND_DOCUMENT",
                                                                                params: {
                                                                                    customerName: voucher?.crParty?.name || "customer",
                                                                                    action: `making payment of Rs. ${beautifyAmount(voucher.amount)}`,
                                                                                    merchant: company?.name || "",
                                                                                    docWithLink: `${publicLink}`,
                                                                                }
                                                                            },"sms");
                                                                        } else {
                                                                            message.error("Failed to send SMS, please contact our Customer Service.");
                                                                            return;
                                                                        }

                                                                        if (!resp.data.success) {
                                                                            message.error(resp.data.message);
                                                                        } else {
                                                                            message.success(`${docType.name} sent on SMS successfully!`);
                                                                        }
                                                                    }}>
                                                                Send on SMS
                                                            </ButtonV2>
                                                        </>
                                                    }/>
                                                </div>
                                            }
                                            title="" trigger="click" onVisibleChange={(visible) => setShowPaymentTooltip(!visible)}>

                                            <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}}>
                                                <div style={{width: '100%'}}>
                                                    <MessageFilled style={{color: 'blue', fontSize: 18, cursor: 'pointer'}}
                                                                   onClick={async (e) => {
                                                                       e.stopPropagation();
                                                                   }}/>
                                                    &nbsp;&nbsp;SMS
                                                </div>
                                            </Menu.Item>
                                        </Popover>

                                        <Menu.Item className={"customHover"} style={{fontWeight: 'bold'}} onClick={() => {
                                            // e.stopPropagation();
                                            // ref.select();
                                            // document.execCommand('copy');
                                            // // This is just personal preference.
                                            // // I prefer to not show the whole text area selected.
                                            // e.target.focus();
                                            copyToClipboard(publicLink);
                                            message.success("Link Copied");
                                        }}>
                                            <div style={{width: '100%'}}>
                                                <LinkOutlined style={{color: '#e040fb', fontSize: 18, cursor: 'pointer'}}
                                                              onClick={(e) => {
                                                              }}/>
                                                &nbsp;&nbsp;Copy Link
                                            </div>
                                        </Menu.Item>
                                    </Menu>
                                }>
                                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                        <Button className={"btnCustom4"}
                                                style={{
                                                    background: '#e8f5e9',
                                                    color: '#1b5e20',
                                                    padding: 0,
                                                    height: 28,
                                                    fontWeight: 'bold',
                                                    fontSize: 13,
                                                    paddingLeft: 8,
                                                    paddingRight: 8
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                        ><ShareAltOutlined/> Send
                                        </Button>
                                    </span>
                                </Dropdown>
                                </>
                                }
                                &nbsp;

                            </>
                    }
                    <Tooltip title={"Edit"}>
                            <Button className={"btnCustom4 hoverOrangeBorder"}
                                    style={{
                                        background: '#ffecb3',
                                        color: '#ff6f00',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("## Btn Clicked ##");
                                        if (voucherType === "receipt") {
                                            console.log("## Dispatching ##");
                                            dispatch({
                                                popData: {
                                                    visible: true,
                                                    type: "receiptWrite",
                                                    title: `Payment Details`,
                                                    data: voucher,
                                                }
                                            });
                                        } else {
                                            navigate(`/vouchers/${voucherType}/edit/${obj._id}`);
                                        }
                                    }}
                            ><EditOutlined style={{color: '#ff6f00'}} />
                            </Button>
                    </Tooltip>
{
    ["invoice", "proforma"].includes(voucherType) &&
    <>
    &nbsp;
                    <Tooltip title={
                        voucherType === "invoice" ? "Clone Invoice (Copy)" : "Convert to Sale Invoice"
                    }>
                            <Button className={"btnCustom4 hoverBlueBorder"}
                                    style={{
                                        background: '#e3f2fd',
                                        color: '#ff6f00',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/vouchers/invoice/create?copyFromVid=${obj._id}`);
                                    }}
                            >
                                {
                                    voucherType === "invoice" ? <CopyOutlined style={{color: '#1976d2'}} /> : <ForwardOutlined style={{color: '#1976d2', fontSize: 16}} />
                                }
                                
                            </Button>
                    </Tooltip>
                    </>
        }
                    {/*</Button>*/}
                    &nbsp;
                    <Popconfirm
                        title="Are you sure to delete this document ?"
                        onConfirm={async (e) => {
                            e.stopPropagation();
                            deleteVoucher(obj._id)
                                .then(() => {
                                    message.success('Document Deleted');
                                    fetchRecords();
                                })
                                .catch(() => message.error('Failed to delete document'))
                        }}
                        onCancel={(e) => {
                            e.stopPropagation();
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip title={"Delete"}>
                            <Button className={"btnCustom4 hoverRedBorder"}
                                    style={{
                                        background: '#ffebee',
                                        color: '#b71c1c',
                                        padding: 0,
                                        height: 28,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        paddingLeft: 8,
                                        paddingRight: 8
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                            ><DeleteOutlined style={{color: '#b71c1c'}} onClick={() => {}}/>
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </div>
            )
        }
    }
].map(c => ({...c, className: "tableSalesCol"})).filter(c => c.include.includes(voucherType));
